import React from 'react';
import { material } from '../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../navbar/Navbar';

function SupplierProductConfig(props) {

    localStorage.setItem("menuName", "Supplier Product Offering Configuration");

    const theme = useTheme();

    return (
        <material.Grid spacing={2}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
        </material.Grid>
    );
}

export default SupplierProductConfig;