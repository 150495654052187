import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { saveSkinTreatment } from '../../services/TreatmentListService';
import Snackbar from '../toastrmessage/Snackbar';

const treatmentName = [
    "Chemical Peel Treatment",
    "IPL Treatment",
    "PDT Light Therapy Treatments",
    "Laser Hair Removal",
    "Microneedling Treatment",
    "Aqua-Infusion Skin Therapy",
    "Facials",
    "Additional Treatment Add-Ons",
    "CO2 Laser Treatment",
    "PICO Laser Treatment"   
];

function AddSkinTreatment(props) {

    const { clinicDetails, clinicID, getSkinTreatmentData, userDetails } = props;
    const { openAddTreatmentDialog, setOpenAddTreatmentDialog } = props;
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [selectTreatment, setSelectTreatment] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddTreatmentDialog.action && openAddTreatmentDialog.treatmentData) {
            reset(openAddTreatmentDialog.treatmentData ? openAddTreatmentDialog.treatmentData : "")
            setSelectTreatment(openAddTreatmentDialog.treatmentData.treatmentName)
        } else if (openAddTreatmentDialog.action) {
            reset({ clinicId: clinicID ? clinicID[0] : "" })
        }
    }, [openAddTreatmentDialog.action === true]);

    const selectTreatmentName = (event) => {
        setSelectTreatment(event.target.value)
    };

    const addTreatment = async (data) => {
        let obj = {
            "orgId": userDetails.orgId,
            "clinicId": clinicDetails.clinicId,
            "treatmentName": data.treatmentName,
            "treatmentArea": data.treatmentArea,
            "productUsed": data.productUsed,
            "numberOfSessions": parseInt(data.numberOfSessions)
        }
        await saveSkinTreatment(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setSelectTreatment("")
                getSkinTreatmentData(clinicDetails.clinicId)
                reset({ treatmentName: "", treatmentArea: "", numberOfSessions: "", productUsed: "", clinicId: "" })
                setOpenAddTreatmentDialog({ "action": false, "treatmentData": null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const updateTreatment = async (data) => {
        let obj = {
            "orgId": openAddTreatmentDialog.treatmentData.orgId,
            "clinicId": openAddTreatmentDialog.treatmentData.clinicId,
            "treatmentName": data.treatmentName,
            "treatmentArea": data.treatmentArea,
            "productUsed": data.productUsed,
            "numberOfSessions": parseInt(data.numberOfSessions),
            "treatmentId": openAddTreatmentDialog.treatmentData.treatmentId
        }
        await saveSkinTreatment(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setSelectTreatment("")
                getSkinTreatmentData(clinicDetails.clinicId)
                reset({ treatmentName: "", treatmentArea: "", numberOfSessions: "", productUsed: "", clinicId: "" })
                setOpenAddTreatmentDialog({ "action": false, "treatmentData": null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const handleClose = () => {
        reset({ treatmentName: "", treatmentArea: "", numberOfSessions: "", productUsed: "", clinicId: "" })
        setOpenAddTreatmentDialog({ "action": false, "treatmentData": null });
        setSelectTreatment("")
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth hideBackdrop open={openAddTreatmentDialog.action}>
                <material.DialogTitle>{openAddTreatmentDialog.treatmentData ? "Edit Skin Treatment" : "Add Skin Treatment"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            <material.FormControl variant="standard" fullWidth required>
                                <material.InputLabel id="treatmentName">Treatment Name</material.InputLabel>
                                <material.Select
                                    {...register("treatmentName", { required: true })}
                                    id="treatmentName"
                                    value={selectTreatment}
                                    onChange={selectTreatmentName}
                                >
                                    {treatmentName?.map((name, i) => (
                                        <material.MenuItem value={name} key={i}>{name}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div>
                            <material.TextField
                                error={errors.treatmentArea?.type === "required"}
                                {...register("treatmentArea", { required: true })}
                                required
                                margin="dense"
                                id="treatmentArea"
                                label="Treatment Area"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div>
                            <material.TextField
                                {...register("numberOfSessions")}
                                margin="dense"
                                id="numberOfSessions"
                                label="Number of Session"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div>
                            <material.TextField
                                {...register("productUsed")}
                                margin="dense"
                                id="productUsed"
                                label="Product Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="contained" sx={{ textTransform: "none" }} color="error">Cancel</material.Button>
                    {openAddTreatmentDialog.treatmentData ? (
                        <material.Button onClick={handleSubmit(updateTreatment)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Update</material.Button>
                    ) : (
                        <material.Button onClick={handleSubmit(addTreatment)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(AddSkinTreatment);